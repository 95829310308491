import React from "react"

const NewsRedirect = () => {
  const isBrowser = typeof window !== "undefined";

  if(isBrowser){
      window.location.href = '/';
  }

  return null;
}

export default NewsRedirect
